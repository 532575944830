/* eslint-disable react-hooks/exhaustive-deps */
import { RealisasiProduksiApi } from "api";
import {
  ActionButton,
  Alert,
  BackButton,
  CRUDLayout,
  DataStatus,
  InputSearch,
  Pagination,
  Table,
  TBody,
  Td,
  TdFixed,
  Th,
  THead,
  ThFixed,
  Tr,
} from "components";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import { ButtonGroup } from "react-bootstrap";
import { IoAddOutline, IoEyeOutline } from "react-icons/io5";
import { useHistory, useLocation } from "react-router-dom";
import { DateConvert, DecimalConvert, TableNumber } from "utilities";
import { ModalDetailProduksi } from "./Comps";

export const RealisasiProduksiListProduksi = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();

  console.log("list", location);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState(undefined);
  const [fetchingStatus, setFetchingStatus] = useState({
    loading: true,
    error: true,
  });
  const [alert, setAlert] = useState({
    show: false,
    variant: "primary",
    text: "",
  });
  const [pagination, setPagination] = useState({
    page: location?.state?.produksi?.page ?? "1",
    dataLength: location?.state?.produksi?.dataLength ?? "10",
    totalPage: location?.state?.produksi?.totalPage ?? "1",
    dataCount: location?.state?.produksi?.dataCount ?? "0",
  });
  const [modal, setModal] = useState({
    show: false,
    id: null,
  });

  const getData = () => {
    setData([]);
    setFetchingStatus({
      loading: true,
      error: true,
    });

    RealisasiProduksiApi.getListProduksi({
      page: pagination.page,
      per_page: pagination.dataLength,
      q: search,
    })
      .then((res) => {
        setData(res?.data?.data ?? []);
        setPagination({
          ...pagination,
          dataCount: res?.data?.data_count,
          totalPage: res?.data?.total_page,
        });
        setFetchingStatus({
          loading: false,
          error: false,
        });
      })
      .catch(() => {
        setData([]);
        setFetchingStatus({
          loading: false,
          error: true,
        });
      });
  };

  const searchHandler = (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    setNavbarTitle("Realisasi Produksi");
    setAlert({
      show: !!history?.location?.state?.alert?.show,
      variant: history?.location?.state?.alert?.variant,
      text: history?.location?.state?.alert?.text,
    });
  }, []);

  useEffect(() => {
    getData();
  }, [search, pagination.page, pagination.dataLength]);

  return (
    <CRUDLayout>
      {/* Head */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-0">
            <InputSearch onChange={debounce(searchHandler, 800)} />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <BackButton
            onClick={() => history.push("/transaksi/realisasi-produksi")}
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <Alert show={alert.show} variant={alert.variant} text={alert.text} />

      {/* Content */}
      {fetchingStatus.loading || fetchingStatus.error ? (
        <DataStatus
          loading={fetchingStatus.loading}
          text={fetchingStatus.loading ? "Memuat . . ." : "Data gagal dimuat"}
        />
      ) : Boolean(data?.length <= 0) ? (
        <DataStatus text="Tidak ada data" />
      ) : (
        <>
          <div className="p-1">
            <b>List Data Produksi Yang Siap Direalisasi</b>
          </div>
          <Table>
            <THead>
              <Tr>
                <ThFixed>No.</ThFixed>
                <ThFixed style={{ minWidth: 151 }} className="p-1">
                  Informasi Produksi
                </ThFixed>
                <Th style={{ minWidth: 300 }} className="p-1">
                  Item Produksi
                </Th>
                <Th style={{ width: 150 }} className="p-1">
                  Qty. Produksi
                </Th>
                <Th style={{ width: 150 }} className="p-1">
                  Total Qty. Telah Direalisasi
                </Th>
                <Th style={{ width: 50 }} className="p-1">
                  Aksi
                </Th>
              </Tr>
            </THead>
            <TBody>
              {data && data?.length > 0 ? (
                data.map((e, i) => (
                  <Tr key={i}>
                    <TdFixed>
                      {TableNumber(pagination.page, pagination.dataLength, i)}
                    </TdFixed>
                    <Td>
                      <span className="d-block">
                        {e?.tgl_jobmix
                          ? DateConvert(new Date(e.tgl_jobmix)).defaultDMY
                          : "-"}
                      </span>
                      <span className="d-block">{e?.no_jobmix ?? "-"}</span>
                    </Td>
                    <Td>
                      {e?.nama_item ?? "-"}{" "}
                      {e?.nama_varian ? ` [${e.nama_varian}]` : ""}
                    </Td>
                    <Td>
                      {e?.qty_jobmix
                        ? DecimalConvert(e.qty_jobmix).getWithComa
                        : "-"}{" "}
                      {e?.nama_satuan ?? ""}
                    </Td>
                    <Td>
                      {DecimalConvert(e.qty_realisasi ?? 0).getWithComa}{" "}
                      {e?.nama_satuan ?? ""}
                    </Td>
                    <Td className="text-center">
                      <ButtonGroup>
                        <ActionButton
                          size="sm"
                          variant="info"
                          text={<IoEyeOutline />}
                          onClick={() =>
                            setModal({ show: true, id: e?.id_jobmix })
                          }
                        />
                        <ActionButton
                          size="sm"
                          text={<IoAddOutline />}
                          onClick={() =>
                            history.push(
                              "/transaksi/realisasi-produksi/tambah/" +
                                e?.id_jobmix,
                              { ...location?.state, produksi: pagination }
                            )
                          }
                        />
                      </ButtonGroup>
                    </Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan={11}>
                    <DataStatus text="Tidak ada data" />
                  </Td>
                </Tr>
              )}
            </TBody>
          </Table>
          <Pagination
            dataLength={pagination?.dataLength}
            dataNumber={
              pagination?.page * pagination?.dataLength -
              pagination?.dataLength +
              1
            }
            dataPage={
              pagination?.dataCount < pagination?.dataLength
                ? pagination?.dataCount
                : pagination?.page * pagination?.dataLength
            }
            dataCount={pagination?.dataCount}
            currentPage={pagination?.page}
            totalPage={pagination?.totalPage}
            onPaginationChange={({ selected }) =>
              setPagination({
                ...pagination,
                page: selected + 1,
              })
            }
            onDataLengthChange={(e) =>
              setPagination({
                ...pagination,
                page: 1,
                dataLength: e.target.value,
              })
            }
          />
          <ModalDetailProduksi
            show={modal.show}
            id={modal.id}
            onHide={() => setModal({ show: false, id: null })}
          />
        </>
      )}
    </CRUDLayout>
  );
};
