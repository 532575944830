// React
import { useEffect, useState } from "react";

// Router
import { useHistory, useLocation } from "react-router-dom";

// Components
import {
  Alert,
  ApprovalStatusButton,
  CreateButton,
  CRUDLayout,
  DataStatus,
  FilterButton,
  InputSearch,
  Pagination,
  ReadButton,
  Table,
  Td,
  TdFixed,
  Th,
  ThFixed,
} from "components";
import { ButtonGroup } from "react-bootstrap";
import { DateConvert, DecimalConvert, TableNumber } from "utilities";

// API
import { JobMixApi } from "api";

// View Components
import { ModalFilter } from "./Section";

// View Functions
import { generateStatusApproval } from "../functions";

const ListJobMix = ({ setNavbarTitle }) => {
  // Hooks
  const history = useHistory();
  const location = useLocation();

  // Variables
  const paginationFromBrowserState = location?.state?.pagination;

  // States
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [dataJobMix, setDataJobMix] = useState([]);
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: location?.state?.produksi?.filter?.active,
      tgl_produksi_mulai: location?.state?.produksi?.filter?.tgl_produksi_mulai,
      tgl_produksi_selesai:
        location?.state?.produksi?.filter?.tgl_produksi_selesai,
      tgl_sales_order_mulai:
        location?.state?.produksi?.filter?.tgl_sales_order_mulai,
      tgl_sales_order_selesai:
        location?.state?.produksi?.filter?.tgl_sales_order_selesai,
      customer: location?.state?.produksi?.filter?.customer,
      barang_jadi: location?.state?.produksi?.filter?.barang_jadi,
      status_progress: location?.state?.produksi?.filter?.status_progress,
    },
    pagination: {
      page: location?.state?.produksi?.filter?.page ?? "1",
      dataLength: location?.state?.produksi?.filter?.dataLength ?? "10",
      totalPage: location?.state?.produksi?.filter?.totalPage ?? "1",
      dataCount: location?.state?.produksi?.filter?.dataCount ?? "0",
    },
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });
  const [searchTime, setSearchTime] = useState(null);

  const getInitialData = () => {
    setIsPageLoading(true);

    JobMixApi.get({
      q: searchConfig.key,
      page: dataFilter?.pagination?.page,
      per_page: dataFilter?.pagination?.dataLength,
      tgl_produksi_mulai: dataFilter?.filter?.tgl_produksi_mulai,
      tgl_produksi_selesai: dataFilter?.filter?.tgl_produksi_selesai,
      tgl_sales_order_mulai: dataFilter?.filter?.tgl_sales_order_mulai,
      tgl_sales_order_selesai: dataFilter?.filter?.tgl_sales_order_selesai,
      customer: dataFilter?.filter?.customer,
      barang_jadi: dataFilter?.filter?.barang_jadi,
      status_progress: dataFilter?.filter?.status_progress,
    })
      .then((data) => {
        setDataJobMix(data?.data?.data ?? []);
        setDataFilter({
          ...dataFilter,
          pagination: {
            ...dataFilter.pagination,
            dataCount: data?.data?.data_count,
            totalPage: data?.data?.total_page,
          },
        });
      })
      .catch((err) => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const onInputSearchChange = (e) => {
    const key = e.target.value;
    if (searchTime) clearTimeout(searchTime);
    setSearchTime(
      setTimeout(() => {
        setSearchConfig({
          ...searchConfig,
          key: e.target.value,
        });
        setDataFilter({
          ...dataFilter,
          pagination: {
            ...dataFilter.pagination,
            page: "1",
            dataLength: "10",
            totalPage: "1",
            dataCount: "0",
          },
        });
        setAlertConfig({
          show: key ? true : false,
          variant: "primary",
          text: "Hasil dari pencarian: " + key,
        });
      }, 750)
    );
  };

  useEffect(() => {
    setNavbarTitle("Produksi");
    getInitialData();

    return () => {
      setIsPageLoading(false);
    };

    // eslint-disable-next-line
  }, [
    setNavbarTitle,
    searchConfig.key,
    dataFilter?.pagination?.page,
    dataFilter?.pagination?.dataLength,
    dataFilter?.filter?.active,
    dataFilter?.filter?.tgl_produksi_mulai,
    dataFilter?.filter?.tgl_produksi_selesai,
    dataFilter?.filter?.tgl_sales_order_mulai,
    dataFilter?.filter?.tgl_sales_order_selesai,
    dataFilter?.filter?.customer,
    dataFilter?.filter?.barang_jadi,
    dataFilter?.filter?.status_progress,
  ]);

  const PageContent = () => {
    const DataTable = () => (
      <Table>
        <thead>
          <tr>
            <ThFixed>No</ThFixed>
            <ThFixed>Aksi</ThFixed>
            <ThFixed>Informasi Produksi</ThFixed>
            <ThFixed>Informasi Sales Order</ThFixed>
            <Th>Customer</Th>
            <Th>Item Barang</Th>
            <Th noPadding width={70}>
              Qty. Sales Order
            </Th>
            <Th noPadding width={70}>
              Qty. Produksi
            </Th>
            <Th>Status Progress</Th>
          </tr>
        </thead>
        <tbody>
          {dataJobMix.map((val, index) => (
            <tr key={index}>
              <Td textCenter>
                {TableNumber(
                  dataFilter?.pagination?.page,
                  dataFilter?.pagination?.dataLength,
                  index
                )}
              </Td>
              <Td>
                <ButtonGroup size="sm">
                  {generateStatusApproval(val).level >= 1 && (
                    <ReadButton
                      onClick={() =>
                        history.push(
                          "/transaksi/job-mix/detail/" + val.id_jobmix,
                          {
                            ...location?.state,
                            produksi: dataFilter,
                          }
                        )
                      }
                    />
                  )}
                  {/* {generateStatusApproval(val).level >= 2 && (
                    <UpdateButton
                      onClick={() =>
                        history.push(
                          "/transaksi/job-mix/ubah/" + val.id_jobmix,
                          {
                            ...location?.state,
                            produksi: dataFilter,
                          }
                        )
                      }
                    />
                  )} */}
                </ButtonGroup>
              </Td>
              <Td className="text-nowrap">
                <div>
                  {val.tgl_jobmix
                    ? DateConvert(new Date(val.tgl_jobmix)).defaultDMY
                    : "-"}
                </div>
                <div>{val.no_jobmix ?? "-"}</div>
              </Td>
              <Td className="text-nowrap">
                <div>
                  {val.tgl_sales_order
                    ? DateConvert(new Date(val.tgl_sales_order)).defaultDMY
                    : "-"}
                </div>
                <div>{val.no_sales_order ?? "-"}</div>
              </Td>
              <Td>{val.nama_customer ?? "-"}</Td>
              <Td>{val.nama_item ?? "-"}</Td>
              <Td>
                {val.qty_item
                  ? `${DecimalConvert(val.qty_item).getWithComa} ${
                      val.kode_satuan ?? ""
                    }`
                  : "-"}
              </Td>
              <Td>
                {val.qty_jobmix
                  ? `${DecimalConvert(val.qty_jobmix).getWithComa} ${
                      val.kode_satuan ?? ""
                    }`
                  : "-"}
              </Td>
              <TdFixed textCenter className="text-nowrap">
                <ApprovalStatusButton
                  variant={generateStatusApproval(val).variant}
                >
                  {val.status_progress === "close" ? "CLOSED" : "ON PROGRESS"}
                </ApprovalStatusButton>
              </TdFixed>
            </tr>
          ))}
        </tbody>
      </Table>
    );

    if (!dataJobMix || dataJobMix.length < 1) {
      return <DataStatus text="Tidak ada data" />;
    }

    return (
      <>
        <b>List Data Produksi</b>
        <DataTable />
        {!searchConfig.status && (
          <Pagination
            dataLength={dataFilter?.pagination?.dataLength}
            dataNumber={
              dataFilter?.pagination?.page *
                dataFilter?.pagination?.dataLength -
              dataFilter?.pagination?.dataLength +
              1
            }
            dataPage={
              dataFilter?.pagination?.dataCount <
              dataFilter?.pagination?.dataLength
                ? dataFilter?.pagination?.dataCount
                : dataFilter?.pagination?.page *
                  dataFilter?.pagination?.dataLength
            }
            dataCount={dataFilter?.pagination?.dataCount}
            currentPage={dataFilter?.pagination?.page}
            totalPage={dataFilter?.pagination?.totalPage}
            onPaginationChange={({ selected }) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: selected + 1,
                },
              })
            }
            onDataLengthChange={(e) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: 1,
                  dataLength: e.target.value,
                },
              })
            }
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      {/* Head Section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch onChange={onInputSearchChange} />
            <FilterButton
              active={dataFilter?.filter?.active}
              onClick={() => setShowModalFilter(true)}
            />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <CreateButton
            onClick={() =>
              history.push("/transaksi/job-mix/sales-order", {
                ...location?.state,
                produksi: dataFilter,
              })
            }
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {/* Content */}
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <PageContent />
      )}

      {showModalFilter && (
        <ModalFilter
          show={showModalFilter}
          setShow={setShowModalFilter}
          data={dataFilter}
          setData={setDataFilter}
        />
      )}
    </CRUDLayout>
  );
};

export default ListJobMix;
