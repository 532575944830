/* eslint-disable react-hooks/exhaustive-deps */
import { RealisasiProduksiApi } from "api";
import {
  Alert,
  CreateButton,
  CRUDLayout,
  DataStatus,
  InputSearch,
  Pagination,
  ReadButton,
  Table,
  TBody,
  Td,
  TdFixed,
  Th,
  THead,
  ThFixed,
  Tr,
} from "components";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { DateConvert, DecimalConvert, TableNumber } from "utilities";

export const RealiasiProduksiList = ({ setNavbarTitle }) => {
  const history = useHistory();
  const [data, setData] = useState([]);
  // const [search, setSearch] = useState(undefined);
  const [searchTime, setSearchTime] = useState(null);
  const [fetchingStatus, setFetchingStatus] = useState({
    loading: true,
    error: true,
  });
  const [alert, setAlert] = useState({
    show: false,
    variant: "primary",
    text: "",
  });
  const [pagination, setPagination] = useState({
    page: 1,
    dataLength: 10,
    totalPage: 1,
    dataCount: 0,
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  });

  const getData = () => {
    setData([]);
    setFetchingStatus({
      loading: true,
      error: true,
    });

    RealisasiProduksiApi.get({
      page: pagination.page,
      per_page: pagination.dataLength,
      q: searchConfig.key,
    })
      .then((res) => {
        setData(res?.data?.data ?? []);
        setPagination({
          ...pagination,
          dataCount: res?.data?.data_count,
          totalPage: res?.data?.total_page,
        });
        setFetchingStatus({
          loading: false,
          error: false,
        });
      })
      .catch(() => {
        setData([]);
        setFetchingStatus({
          loading: false,
          error: true,
        });
      });
  };

  const onInputSearchChange = (e) => {
    const key = e.target.value;
    if (searchTime) clearTimeout(searchTime);
    setSearchTime(
      setTimeout(() => {
        setSearchConfig({
          ...searchConfig,
          key: e.target.value,
        });
        setAlert({
          show: key ? true : false,
          variant: "primary",
          text: "Hasil dari pencarian: " + key,
        });
      }, 750)
    );
  };

  // const searchHandler = (e) => {
  //   setSearch(e.target.value);
  // };

  useEffect(() => {
    setNavbarTitle("Realisasi Produksi");
    setAlert({
      show: !!history?.location?.state?.alert?.show,
      variant: history?.location?.state?.alert?.variant,
      text: history?.location?.state?.alert?.text,
    });
  }, []);

  useEffect(() => {
    getData();
  }, [searchConfig.key, pagination.page, pagination.dataLength]);

  return (
    <CRUDLayout>
      {/* Head */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-0">
            <InputSearch onChange={onInputSearchChange} />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <CreateButton
            onClick={() =>
              history.push("/transaksi/realisasi-produksi/list-produksi")
            }
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <Alert
        showCloseButton
        show={alert.show}
        variant={alert.variant}
        text={alert.text}
        onClose={() => setAlert((prev) => ({ ...prev, show: false }))}
      />

      {/* Content */}
      {fetchingStatus.loading || fetchingStatus.error ? (
        <DataStatus
          loading={fetchingStatus.loading}
          text={fetchingStatus.loading ? "Memuat . . ." : "Data gagal dimuat"}
        />
      ) : Boolean(data?.length <= 0) ? (
        <DataStatus text="Tidak ada data" />
      ) : (
        <>
          <div className="p-1">
            <b>List Data Realisasi Produksi</b>
          </div>
          <Table>
            <THead>
              <Tr>
                <ThFixed>No.</ThFixed>
                <ThFixed>Aksi</ThFixed>
                <ThFixed style={{ minWidth: 150 }} className="p-1">
                  Informasi Realisasi Produksi
                </ThFixed>
                <ThFixed style={{ minWidth: 150 }} className="p-1">
                  Informasi Produksi
                </ThFixed>
                <Th style={{ minWidth: 300 }} className="p-1">
                  Item Produksi
                </Th>
                <Th style={{ width: 150 }} className="p-1">
                  Qty. Produksi
                </Th>
                <Th style={{ width: 150 }} className="p-1">
                  Qty. Realisasi Produksi
                </Th>
              </Tr>
            </THead>
            <TBody>
              {data && data?.length > 0 ? (
                data.map((e, i) => (
                  <Tr key={i}>
                    <TdFixed>
                      {TableNumber(pagination.page, pagination.dataLength, i)}
                    </TdFixed>
                    <TdFixed>
                      <div className="d-flex">
                        <ReadButton
                          size="sm"
                          onClick={() =>
                            history.push(
                              "/transaksi/realisasi-produksi/detail/" +
                                e?.id_realisasi_produksi
                            )
                          }
                        />
                      </div>
                    </TdFixed>
                    <Td>
                      <span className="d-block">
                        {e?.tgl_realisasi_produksi
                          ? DateConvert(new Date(e.tgl_realisasi_produksi))
                              .defaultDMY
                          : "-"}
                      </span>
                      <span className="d-block">
                        {e?.no_realisasi_produksi ?? "-"}
                      </span>
                    </Td>
                    <Td>
                      <span className="d-block">
                        {e?.tgl_jobmix
                          ? DateConvert(new Date(e.tgl_jobmix)).defaultDMY
                          : "-"}
                      </span>
                      <span className="d-block">{e?.no_jobmix ?? "-"}</span>
                    </Td>
                    <Td>{e?.nama_item ?? "-"}</Td>
                    <Td>
                      {e?.qty_jobmix
                        ? DecimalConvert(e.qty_jobmix).getWithComa
                        : "-"}{" "}
                      {e?.kode_satuan ?? ""}
                    </Td>
                    <Td>
                      {e?.qty_realisasi_produksi
                        ? DecimalConvert(e.qty_realisasi_produksi).getWithComa
                        : "-"}{" "}
                      {e?.kode_satuan ?? ""}
                    </Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan={11}>
                    <DataStatus text="Tidak ada data" />
                  </Td>
                </Tr>
              )}
            </TBody>
          </Table>
          <Pagination
            dataLength={pagination?.dataLength}
            dataNumber={
              pagination?.page * pagination?.dataLength -
              pagination?.dataLength +
              1
            }
            dataPage={
              pagination?.dataCount < pagination?.dataLength
                ? pagination?.dataCount
                : pagination?.page * pagination?.dataLength
            }
            dataCount={pagination?.dataCount}
            currentPage={pagination?.page}
            totalPage={pagination?.totalPage}
            onPaginationChange={({ selected }) =>
              setPagination({
                ...pagination,
                page: selected + 1,
              })
            }
            onDataLengthChange={(e) =>
              setPagination({
                ...pagination,
                page: 1,
                dataLength: e.target.value,
              })
            }
          />
        </>
      )}
    </CRUDLayout>
  );
};
