import jwtDecode from "jwt-decode";

export const GuestMiddleware = ({ children }) => {
  const token = sessionStorage.getItem("token");
  const decode = jwtDecode(token);
  const { hak } = decode;

  if (hak.includes("VISIT")) {
    return null;
  }

  return children;
};
